import React from 'react';
import './style.css'; // Importa a folha de estilo CSS

const BackgroundWithImage = ({ photoUrl, backgroundImage }) => {
    
    return (
        <div className="container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='info'>
                <div className="photo-sphere" style={{ backgroundImage: `url(${photoUrl})` }}>
                </div>
                <div className="info-container">
                    <h1>Leticia Kammer</h1>
                    <p>CRP 12/22009</p>
                </div>
                <a href="https://leticiakammer.com.br/" target="_self" style={{ textDecoration: 'none' }}>
                    <button>Visite meu site</button>
                </a>
                <a href="https://wa.me/5548996209579" target="_self" style={{ textDecoration: 'none' }}>
                    <button>Agende sua consulta</button>
                </a>
                <a href="https://www.instagram.com/leticiakammer.psi?igsh=ODg3dnA2aXpjYzFo" target="_self" style={{ textDecoration: 'none' }}>
                    <button>Me acompanhe no Instagram</button>
                </a>
            </div>
        </div>
    );
};

export default BackgroundWithImage;