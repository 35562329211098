import './App.css';
import BackgroundWithImage from './components/BackgroundWithImage';

function App() {
  return (
    <div className="App">
        <BackgroundWithImage backgroundImage={'/background.webp'} photoUrl={'/profile.jpeg'}/>
    </div>
  );
}

export default App;
